import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Row, Col, Dropdown, Form, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// COMPONENT IMPORTS
import { socket } from '../../config/Socket';
import { WL_PATHS } from '../../constants/system';
import Modal from '../../../src/components/Modal';
import { localObjectClear } from '../../utils/system';
import CapsyncIcon from '../../components/CapsyncIcon';
import Avatar from '../../components/userProfile/Avatar';
import Notifications from '../Settings/components/Notifications';

// API
import {
  authUserLogout,
  toAccessUserList,
  userSignOut,
  useToAccessUser,
  useUser,
  useUserID
} from '../../slices/authSlice';
import { setIsFmExpanded, toggleSidebar } from '../../slices/systemSlice';
import { getNotificationList } from '../../slices/notificationSlice';
import { getClientsByCollabUser } from '../../slices/collaborationSlice';
import { addRecentlyViewedIndividual } from '../../slices/clientSlice';

/* ============================== HEADER ============================== */
const Header = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const toAccessUser = useToAccessUser();
  const userId = useUserID();
  const user = useUser();
  const userRole = user?.role_id;
  const isDomainVerified = user?.firm?.firm_brand_details?.is_domain_verified;
  const { savedAvatar } = useSelector((state) => state.auth);
  const { activeNav } = useSelector((state) => state.system);
  const redirectDomain = user?.firm?.firm_brand_details?.domain;
  const token = window.localStorage.getItem('token');
  const professionalUserId = user?.id;

  const notificationRef = useRef(null);
  const iconRef = useRef(null);

  const [isLogout, setIsLogout] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [refreshNotification, setRefreshNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [clientAccess, setClientAccess] = useState(toAccessUser);
  const [searchClient, setSearchClient] = useState('');
  const [disableClient, setDisableClient] = useState(false);
  const trimedSrc =
    user &&
    user?.user_personal_details &&
    user?.user_personal_details?.profile_image_url &&
    user?.user_personal_details?.profile_image_url.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );

  const handleToggleSidebar = () => dispatch(toggleSidebar());

  const getAllClientList = async () => {
    await dispatch(getClientsByCollabUser(userId)).unwrap();
  };

  const getToAccessUserList = async () => {
    try {
      await dispatch(
        toAccessUserList({
          user_id: userId
        })
      ).unwrap();
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  useEffect(() => {
    getToAccessUserList();
    getAllClientList();
    socket.on('sync_client_list', () => {
      getToAccessUserList();
    });
    socket.on('sync_client_access_list', () => {
      getToAccessUserList();
    });
    return () => {
      socket.off('sync_client_list', () => {
        getToAccessUserList();
      });
      socket.off('sync_client_access_list', () => {
        getToAccessUserList();
      });
    };
  }, []);

  const handleLogoutUser = async () => {
    const response = await dispatch(userSignOut()).unwrap();
    if (response) {
      dispatch(authUserLogout());
      socket.emit('logoutAccessWl', {
        user_id: userId
      });
      localObjectClear();
      const logoutChannel = new BroadcastChannel('logout_channel');
      logoutChannel.postMessage('logout');
      logoutChannel.close();
      navigation('/');
    }
  };

  const reDirectToClientPage = async (clientId) => {
    try {
      setDisableClient(true);
      const response = await dispatch(addRecentlyViewedIndividual({ user_id: clientId })).unwrap();
      if (response.code === 200) {
        socket.emit('addAccess', {
          user_id: professionalUserId,
          access_user_id: clientId
        });
        if (process.env.REACT_APP_MODE == 'LOCAL') {
          window.open(
            `http://${redirectDomain}:3333/redirect/master-admin/${token}/${professionalUserId}/${clientId}/${userRole}`,
            '_self'
          );
        } else {
          window.open(
            `https://${redirectDomain}/redirect/master-admin/${token}/${professionalUserId}/${clientId}/${userRole}`,
            '_self'
          );
        }
        setDisableClient(false);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setDisableClient(false);
    }
  };

  useEffect(() => {
    const searchResult = user?.professional_user_access?.filter((items) => {
      const name = `${items?.first_name?.toLowerCase()} ${items?.last_name?.toLowerCase()}`;
      return name.includes(searchClient?.toLowerCase());
    });
    if (searchClient == '') {
      setClientAccess(toAccessUser);
    } else {
      setClientAccess(searchResult);
    }
  }, [searchClient, toAccessUser]);

  useEffect(() => {
    handleNotification();
    return () => {
      setRefreshNotification(false);
    };
  }, [refreshNotification, openNotification]);

  const handleNotification = () => {
    dispatch(getNotificationList(user?.id))
      .unwrap()
      .then((res) => {
        if (res.status) {
          setNotificationData(res.data);
        }
      });
  };

  useEffect(() => {
    if (user) {
      socket.on('send_notification', handleNotification);
    }
    return () => {
      socket.off('send_notification', handleNotification);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [!!refreshNotification]);

  return (
    <React.Fragment>
      {isDomainVerified === false && (
        <div className="upgrade-navbar for-mob">
          <span className="cs-regular-h5">
            <span className="cs-icon cs-error">
              <CapsyncIcon title="error-validation-filled" size="22" />
            </span>
            <p className="cs-primary">Company branding and configuration in process</p>
          </span>
        </div>
      )}
      <div className="cs-header-widget">
        <Navbar className="cs-header">
          <div className="container-fluid">
            <Row>
              <Col>
                <div className="cs-menu-nav">
                  <div className="cs-header-left" onClick={handleToggleSidebar}>
                    <span className="hamburger-outlined cs-menu-toggle cs-icon" id="cs-menu-toggle">
                      <CapsyncIcon title="hamburger-outlined" size="22" />
                    </span>
                  </div>
                  <div className="cs-header-center cs-navbar-dropdown-btn dropdown">
                    <Dropdown>
                      <Dropdown.Toggle className="cs-pfs-search" id={'my-pfs-dropdown'}>
                        My Dashboard
                        <CapsyncIcon title="chevron-down-outlined" size="14" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="cs-pfs-menu">
                        <Dropdown.Item className="cs-active-accounts pfs-item" id="my-dashboard">
                          <span className="cs-light-body-text-m cs-neutral-100">My Dashboard</span>
                        </Dropdown.Item>
                        <span className="cs-search-text cs-regular-sub-heading-s cs-neutral-60">
                          Shared accounts
                        </span>

                        <div className="cs-search-bar">
                          <Form.Group className="cs-form-group">
                            <Form.Control
                              type="text"
                              placeholder="Search account"
                              onChange={(e) => setSearchClient(e.target.value)}
                            />
                            <span className="input-field-icon cs-neutral-70">
                              <CapsyncIcon title="search-outlined" size="18" />
                            </span>
                          </Form.Group>
                        </div>
                        {clientAccess?.length > 0 ? (
                          clientAccess?.map((item, index) => {
                            return (
                              <span
                                className={`cs-search-result cs-light-body-text-m cs-neutral-90 ${disableClient ? 'cs-disabled' : ''}`}
                                key={index}
                                // onClick={() => {
                                //   reDirectToClientPage(item?.user_id);
                                // }}
                                onClick={() => {
                                  if (!disableClient) {
                                    reDirectToClientPage(item?.user_id);
                                  }
                                }}>
                                {`${item?.first_name} ${item?.last_name} `}
                              </span>
                            );
                          })
                        ) : (
                          <span className="cs-search-result cs-light-body-text-m cs-neutral-60 dropdown-no-record">
                            No shared accounts available
                          </span>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="cs-header-right navbar-wrapper navbar-nav">
                    {isDomainVerified === false && (
                      <div className="upgrade-navbar for-des">
                        <span className="cs-regular-h5">
                          <span className="cs-icon cs-error">
                            <CapsyncIcon title="error-validation-filled" size="22" />
                          </span>

                          <p className="cs-primary">
                            Company branding and configuration in process
                          </p>
                        </span>
                      </div>
                    )}
                    <div className="cs-header-notification">
                      <NavDropdown
                        ref={notificationRef}
                        title={
                          <div
                            ref={iconRef}
                            onClick={() => setOpenNotification(!openNotification)}
                            className={
                              !savedAvatar &&
                              notificationData?.filter((item) => !item.read_status).length === 0 &&
                              'action-required'
                            }>
                            <span className="cs-cursor-pointer cs-icon" id="cs-header-notification">
                              <CapsyncIcon title="notification-outlined" size="22" />
                            </span>
                            {(!savedAvatar ||
                              notificationData?.filter((item) => !item.read_status).length > 0) && (
                              <span span className="cs-notification-badge"></span>
                            )}
                          </div>
                        }
                        className="cs-header-dropdown nav-icons cs-header-notification-bell">
                        {openNotification && (
                          <Notifications
                            notificationData={notificationData}
                            setRefreshNotification={setRefreshNotification}
                          />
                        )}
                      </NavDropdown>
                    </div>
                    <div className="cs-header-profile">
                      <NavDropdown
                        title={
                          <Avatar
                            className={`cs-avatar-xs ${trimedSrc === '/users/avatar/avatar_6.png' || user.user_personal_details.profile_image_url === null ? 'cs-avatar-text' : 'cs-avatar-img'}`}
                            src={`${user.user_personal_details.profile_image_url}`}
                          />
                        }
                        className="cs-relative cs-dropdown-nav">
                        <NavDropdown.Item
                          className={`profile-item ${activeNav === WL_PATHS.SETTINGS ? 'active' : ''}`}
                          onClick={() => {
                            navigation('/dashboard/settings/account-details');
                            dispatch(setIsFmExpanded(false));
                          }}
                          eventKey="link-11">
                          <span className="cs-icon">
                            <CapsyncIcon
                              title={
                                activeNav === WL_PATHS.SETTINGS
                                  ? 'settings-filled'
                                  : 'settings-outlined'
                              }
                              size="22"
                            />
                            Settings
                          </span>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="profile-item"
                          eventKey="link-12"
                          onClick={() => setIsLogout(true)}>
                          <span className="cs-icon">
                            <CapsyncIcon title="logout-outlined" size="22" />
                          </span>
                          Log Out
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Navbar>
        {isLogout && (
          <Modal
            show={isLogout}
            title="Confirm Log Out"
            className="cs-md-modal"
            parentClassName="cs-common-modal-overlay"
            isCloseButton={false}
            body={
              <div className="cs-text-center logout-modal">
                <span className="icon cs-icon">
                  <CapsyncIcon title="error-validation" size="100" />
                </span>
                <div className="cs-regular-body-text-m cs-neutral-90">
                  Are you sure you want to logout?
                </div>
              </div>
            }
            cancelButtonLabel="Log out"
            saveButtonLabel="Stay here"
            handleOnSave={() => setIsLogout(false)}
            handleOnCancel={handleLogoutUser}
            deleteButtonText="Stay here"
            modalFooterClass="cs-center-btn"
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Header;
