import React, { useEffect } from 'react';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

// COMPONENT IMPORTS
import AuthBanner from './AuthBanner';
import Logo from '../assets/images/brand/capsync-logo.svg';

// API
import { useBrandDetails } from '../slices/brandDetailSlice';

/* ============================== PAYMENT SUCCESS ============================== */
const PaymentSuccess = () => {
  const brandDetails = useBrandDetails();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/sign-in');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  const handleContinueClick = () => {
    navigate('/sign-in');
  };

  return (
    <section className="auth-section sign-in-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              <div className="cs-auth-header">
                <Image
                  className="cs-logo"
                  width="132"
                  src={
                    brandDetails && brandDetails?.is_domain_verified === true
                      ? brandDetails.primary_logo_s3_url
                      : Logo
                  }
                  alt="Capsync Logo"
                />
                <div className="cs-title">
                  <h1 className="cs-semi-bold-h1">Payment Success!</h1>
                </div>
                <p className="cs-regular-sub-heading-s cs-neutral-80">
                  Thank you for your payment! A confirmation email has been sent to your inbox.
                </p>
                <div className="auth-footer">
                  <Button
                    className="cs-btn-primary lg-btn cs-regular-h5"
                    onClick={handleContinueClick}>
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PaymentSuccess;
