import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from './Modal';
import CapsyncIcon from './CapsyncIcon';

/* ============================== PAYMENT PENDING MODAL ============================== */
const PaymentPendingAlertModal = ({ isOpen = false, onCloseClick }) => {
  return (
    <Modal
      body={
        <div className="cs-text-center log-out-popup">
          <span className="icon cs-icon">
            <CapsyncIcon title="error-validation" size="100" />
          </span>
          <p className="cs-regular-body-text-m cs-neutral-90 text-center">
            To continue using your account, please check your email and complete your subscription
            payment.
          </p>
        </div>
      }
      show={isOpen}
      isCloseButton={false}
      handleOnCancel={onCloseClick}
      title="Payment Pending"
      cancelButtonLabel="Cancel"
      parentClassName="cs-common-modal-overlay"
      className="cs-md-modal"
      modalFooterClass="cs-center-btn"
    />
  );
};

// PROPS TYPE
PaymentPendingAlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func
};

export default PaymentPendingAlertModal;
