import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import CONFIG from '../constants/config';

export const getAuditLogs = createAsyncThunk(
  'activity_logs',
  async ({ userId, roleId, search = '', page = 1, limit = 10, isPaginate = true, order = 'ASC' }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}activity_logs/${userId}/${roleId}`, {
      params: { search, page, limit, isPaginate, order }
    });
    return res;
  }
);

export const billingHistory = createAsyncThunk(
  'settings/billingHistory',
  async ({ userID, userRole, params }) => {
    return await axios.get(`/payment/get_payment_history/${userID}/${userRole}`, { params });
  }
);

// INITIAL STATE
const initialState = {
  auditLogs: {}
};

/* ============================== AUDIT LOG SLICE ============================== */
export const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAuditLogs.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.auditLogs = data;
    });
  }
});

const { reducer } = auditLogsSlice;
export default reducer;
