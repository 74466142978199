import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FixedCropper, CircleStencil } from 'react-advanced-cropper';

// CSS IMPORTS
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/corners.css';
import { CustomWrapper } from './CustomWrapper';

/* ============================== CROP AVATAR ============================== */
export const CropAvatar = ({ img, imgRef, imgType }) => {
  const imgConfig = {
    primary: { stencilComponent: undefined },
    small: { ratio: 1, stencilComponent: undefined },
    Avatar: { ratio: 1, stencilComponent: CircleStencil }
  };

  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.getImage();
    }
  }, [img]);

  return (
    <div className={`favicon-${imgType}`}>
      <FixedCropper
        ref={imgRef}
        src={img}
        stencilSize={{
          width: 320,
          height: 90
        }}
        // areaSizeRestrictions={{
        //   minWidth: 100,
        //   minHeight: 100,
        // }}
        stencilComponent={imgConfig[imgType]?.stencilComponent}
        imageRestriction="stencil"
        stencilProps={{
          aspectRatio: imgConfig[imgType]?.ratio,
          handlers: false,
          lines: true,
          movable: false,
          resizable: false
        }}
        backgroundWrapperProps={{
          scaleImage: false
        }}
        wrapperComponent={(props) => (
          <CustomWrapper {...props} />
        )}
      />
    </div>
  );
};

// PROPS TYPE
CropAvatar.propTypes = {
  img: PropTypes.string.isRequired,
  imgRef: PropTypes.any,
  imgType: PropTypes.string
};
