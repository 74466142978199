import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { socket } from '../../../config/Socket';
import moment from 'moment';

// COMPONENT IMPORTS
import CustomTable from '../../../components/CustomTable';
import CapsyncIcon from '../../../components/CapsyncIcon';
import AddIndividualModal from '../components/AddIndividualModal';
import UserDetailsTeamCollab from '../components/UserDetailsTeamCollab';
import ManageClientModel from '../components/ManageClientModel';
import ChooseUserModal from '../components/ChooseUserModal';
import DeleteModal from '../../../components/DeleteModal';
import AddClientModal from '../../dashboard/components/AddClientModal';
import DropdownBox from '../../../components/FormFields/DropdownBox';
import SkeletonAuditlog from '../../components/skeleton/SkeletonAuditlog';
import PaymentPendingAlertModal from '../../../components/PaymentPendingAlertModal';

// API
import {
  allCollaborationUsersList,
  allPermissionList,
  changeUserPermission,
  deleteCollabUser,
  getClientsByCollabUser,
  resetError
} from '../../../slices/collaborationSlice';
import { getUserDetails, resendEmailVerification, useUserID } from '../../../slices/authSlice';
import { useBrandDetails } from '../../../slices/brandDetailSlice';

/* ============================== TEAM COLLABORATION ============================== */
const TeamCollaboration = () => {
  const dispatch = useDispatch();

  const userId = useUserID();
  const brandDetails = useBrandDetails();
  const { user } = useSelector((state) => state.auth);
  const { permissionOptionList } = useSelector((state) => state.collaboration);

  const [isAlertModelOpen, setIsAlertModelOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState({
    addUser: false,
    viewDetails: false,
    manageClient: false,
    addClient: false,
    removeUser: false,
    deletePrompt: false
  });
  const [viewDetailsData, setViewDetailsData] = useState(null);
  const [manageClientModelId, setManageClientModelId] = useState(-1);
  const [usersRecord, setUsersRecord] = useState({});
  const [removeUserId, setRemoveUserId] = useState(-1);
  const [zeroClient, setZeroClient] = useState([]);
  const [updateCollabTable, setUpdateCollabTable] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const handleRoleChange = () => {
      if (userId) dispatch(getUserDetails(userId));
      onPaginationValueChange({
        page: 1,
        limit: 10,
        isPaginate: true
      });
    };
    socket.on('role_changed', handleRoleChange);
    socket.on('sync_collaboration_list', () => {
      onPaginationValueChange({
        page: 1,
        limit: 10,
        isPaginate: true
      });
    });
    return () => {
      socket.off('role_changed', handleRoleChange);
      socket.off('sync_collaboration_list', () => {
        onPaginationValueChange({
          page: 1,
          limit: 10,
          isPaginate: true
        });
      });
    };
  }, []);

  useEffect(() => {
    if (permissionOptionList?.length === 0) {
      dispatch(allPermissionList());
    }
    getAllCollaborationUsersList({
      page: 1,
      limit: 10
    });
    return () => {
      setUpdateCollabTable(false);
    };
  }, [updateCollabTable]);

  const getAllCollaborationUsersList = async (params) => {
    const response = await dispatch(allCollaborationUsersList(params));
    setUsersRecord(response?.payload?.data);
  };

  const handleResendInvitation = async (item) => {
    dispatch(resendEmailVerification({ email: item.email, is_team_collaboration: true })).then(
      (res) => {
        if (res.payload.status) {
          toast.success(res.payload.message);
        } else {
          toast.error(res.payload.message);
        }
      }
    );
  };

  const onHandlePermissionChange = async (option, id) => {
    const response = await dispatch(
      changeUserPermission({
        userId: id,
        body: {
          role_id: option.toString()
        }
      })
    ).unwrap();
    if (response) {
      if (response.status) {
        getAllCollaborationUsersList();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };

  const onPaginationValueChange = (option) => {
    const paginationLimits = {
      page: option.page,
      limit: option.limit,
      isPaginate: option.limit !== 'all' ? true : false
    };
    getAllCollaborationUsersList(paginationLimits);
  };

  const COLUMNS = [
    { label: 'Name', width: '320', renderCell: (item) => item.display_name },
    {
      label: 'Permission',
      width: '320',
      renderCell: (item) => {
        const selectedOption = {
          label: permissionOptionList?.length
            ? permissionOptionList.find((opt) => opt.value === item.role_id)?.label
            : '',
          value: item.role_id
        };
        // const options = permissionOptionList.map((opt) => {
        //   return {
        //     ...opt,
        //     disabled: selectedOption?.value === opt?.value
        //   };
        // });
        const options =
          Array.isArray(permissionOptionList) && permissionOptionList.length > 0
            ? permissionOptionList.map((opt) => {
              return {
                ...opt,
                disabled: selectedOption?.value === opt?.value
              };
            })
            : [];
        return (
          <DropdownBox
            value={selectedOption}
            handleChangeSelect={(opt) => onHandlePermissionChange(opt, item.id)}
            options={options}
            placeholder="Select from Admin or standard user"
            name="permission"
            tooltip={true}
            disabled={user.email === item.email}
            parentClassName={user.email === item.email ? 'cs-disabled' : ''}
          />
        );
      }
    },
    {
      label: 'Last Login',
      width: '194',
      renderCell: (item) => {
        if (item.last_login) {
          const currentTime = new Date();
          const lastLoginTime = new Date(item.last_login);
          const diffTime = Math.abs(currentTime - lastLoginTime);
          const secondsInMinute = 60 * 1000;
          const secondsInDay = 24 * 60 * 60 * 1000;

          if (diffTime < secondsInMinute) {
            const duration = moment.duration(diffTime);
            const seconds = duration.seconds();
            return `${seconds} seconds ago`;
          } else if (diffTime < secondsInDay) {
            return moment(item.last_login).fromNow();
          } else {
            return moment(item.last_login).format('L');
          }
        }
      }
    },
    {
      label: 'Client Assigned',
      width: '194',
      renderCell: (item) => (
        <span
          className={
            item?.email_verification_status && item.is_profile_set
              ? 'cursor-pointer cs-primary'
              : 'cs-disabled cs-neutral-60'
          }
          onClick={() => {
            if (item?.email_verification_status && item.is_profile_set) {
              handleOpenModel('manageClient');
              setManageClientModelId(item.id);
            }
          }}>
          View & Manage
        </span>
      )
    },
    {
      label: 'Date Added',
      width: '194',
      renderCell: (item) =>
        item?.email_verification_status && item.is_profile_set ? (
          moment(item.createdAt).format('L')
        ) : (
          <span className="cs-regular-body-text-s cs-badge cs-warning">Pending</span>
        )
    },
    {
      label: 'Clients',
      width: '194',
      renderCell: (item) => item['professional_user_access.count']
    },
    {
      label: 'Actions',
      width: '194',
      renderCell: (item) => (
        <div className="cs-table-icons">
          <span
            onClick={() => {
              if (user.email !== item.email) {
                handleOpenModel('deletePrompt');
                getAllClientList(item);
                setRemoveUserId(item.id);
              }
            }}
            className={`delete-outlined cs-delete-btn ${user.email === item.email ? 'cs-disabled' : 'cursor-pointer'}`}>
            <CapsyncIcon title="delete-outlined" size="14" />
          </span>
          {!item.is_profile_set && (
            <span className="collaboration-action-drop">
              <Dropdown className="cs-form-dropdown">
                <Dropdown.Toggle>
                  <span className="cursor-pointer cs-neutral-80">
                    <CapsyncIcon title="option-horizontal-filled" size="18" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleResendInvitation(item)}>
                    Resend invitation
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          )}
        </div>
      )
    },
    {
      label: '',
      width: '104',
      renderCell: (item) => (
        <div
          className="text-end cursor-pointer "
          onClick={() => {
            setViewDetailsData(item);
            handleOpenModel('viewDetails');
          }}>
          <span className="cs-neutral-100 cs-regular-sub-heading-m">Details</span>
          <span className="cs-neutral-100 chevron-right-outlined">
            <CapsyncIcon title="chevron-right-outlined" size="16" />
          </span>
        </div>
      )
    }
  ];

  const handleCloseModel = (key) => {
    if (key === 'addUser') {
      dispatch(resetError());
    }
    if (key === 'manageClient') {
      setManageClientModelId(-1);
      setUpdateCollabTable(true);
    }
    if (key === 'removeUser') {
      getAllCollaborationUsersList({
        page: usersRecord?.pagination?.page,
        limit: usersRecord?.pagination?.limit
      });
      setRemoveUserId(-1);
    }
    if (key === 'addClient') {
      return setIsModelOpen({
        ...isModelOpen,
        manageClient: true,
        [key]: false
      });
    }
    setIsModelOpen({
      ...isModelOpen,
      [key]: false
    });
  };

  const getAllClientList = async (item) => {
    const userId = item?.id;
    try {
      const response = await dispatch(getClientsByCollabUser(userId)).unwrap();
      setZeroClient(response);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleDelete = async () => {
    setLoadingState(true)
    try {
      if (zeroClient?.length > 0) {
        handleOpenModel('removeUser');
      } else {
        const response = await dispatch(
          deleteCollabUser({
            professional_user_id: removeUserId,
            assign_to: ''
          })
        ).unwrap();
        if (response) {
          if (response.status) {
            toast.success(response.message);
            getAllCollaborationUsersList({
              page: 1,
              limit: 10
            });
            handleCloseModel('deletePrompt');
          }
        }
      }
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      setLoadingState(false)
    }
  };

  const handleOpenModel = (key, item) => {
    if (key === 'removeUser') {
      return setIsModelOpen({
        ...isModelOpen,
        deletePrompt: false,
        [key]: true
      });
    }
    setIsModelOpen({
      ...isModelOpen,
      [key]: true
    });
  };

  const handleAddMoreClient = () => {
    setIsModelOpen({
      ...isModelOpen,
      manageClient: false,
      addClient: true
    });
  };

  const [loading] = useOutletContext();

  return (
    <div className="cs-setting-page team-collaboration-sec">
      {loading ? (
        <SkeletonAuditlog />
      ) : (
        <React.Fragment>
          <Button
            onClick={() => {
              brandDetails?.is_payment_success
                ? handleOpenModel('addUser')
                : setIsAlertModelOpen(true);
            }}
            className="cs-btn-icon-primary lg-btn cs-regular-h5">
            <span className="add-filled cs-neutral-10 icon cs-icon">
              <CapsyncIcon title="add-filled" size="18" />
            </span>
            <span className="cs-nav-text">Add User</span>
          </Button>
          <CustomTable
            columns={COLUMNS}
            data={usersRecord && usersRecord.records ? usersRecord.records : []}
            isPaginate={true}
            className="team-collaboration-table"
            totalRecords={
              usersRecord?.pagination?.itemCount
                ? usersRecord.pagination.itemCount
                : usersRecord?.records?.length
            }
            onPaginationValueChange={onPaginationValueChange}
          />
        </React.Fragment>
      )}
      {isModelOpen?.addUser && (
        <AddIndividualModal
          handleCloseModel={() => handleCloseModel('addUser')}
          getAllCollaborationUsersList={(params) => getAllCollaborationUsersList(params)}
        />
      )}

      {isModelOpen.manageClient && (
        <ManageClientModel
          handleCloseModel={() => handleCloseModel('manageClient')}
          userId={manageClientModelId}
          handleAddMoreClient={handleAddMoreClient}
          manageClientModelId={manageClientModelId}
        />
      )}

      {isModelOpen?.removeUser && (
        <ChooseUserModal
          handleCloseModel={() => handleCloseModel('removeUser')}
          userId={removeUserId}
          manageClientModelId={removeUserId}
          type="TeamCollaboration"
        />
      )}

      <UserDetailsTeamCollab
        handleCloseModel={() => handleCloseModel('viewDetails')}
        data={viewDetailsData}
        show={isModelOpen.viewDetails}
      />

      {isModelOpen.deletePrompt && (
        <DeleteModal
          show={isModelOpen.deletePrompt}
          onHandleClose={() => handleCloseModel('deletePrompt')}
          onHandleConfirm={() => {
            handleDelete();
          }}
          deleteBodyText="You are about to delete this user, but before deleting this user, you need to re-assign clients. By hitting continue, you can move forward."
          deleteButtonText="Continue"
          loading={loadingState}
          disabled={loadingState}
        />
      )}

      {isModelOpen.addClient && (
        <AddClientModal
          manageClientModelId={manageClientModelId}
          handleCloseModel={() => handleCloseModel('addClient')}
          typeModal="TeamCollaboration"
        />
      )}

      {isAlertModelOpen && (
        <PaymentPendingAlertModal
          isOpen={isAlertModelOpen}
          onCloseClick={() => setIsAlertModelOpen(false)}
        />
      )}
    </div>
  );
};

export default TeamCollaboration;
