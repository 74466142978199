import React, { useEffect, useState } from 'react';
import { Accordion, Button, Dropdown, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';
import CustomTable from './CustomTable';
import config from '../constants/config';
import { socket } from '../config/Socket';
import { formatPhoneNumber } from '../utils/common';
import { TOAST_MESSAGE } from '../constants/message';
import DeleteIndividualModal from '../pages/dashboard/components/DeleteIndividualModal';

// API
import { resendEmailVerification } from '../slices/authSlice';
import { addRecentlyViewedCompany, deleteClient } from '../slices/clientSlice';

const paginationDropdownOptions = [
  {
    label: '10',
    value: 10
  },
  {
    label: '15',
    value: 15
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '25',
    value: 25
  },
  {
    label: '30',
    value: 30
  },
  {
    label: 'All',
    value: 'all'
  }
];

const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
  if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
    return rowsPerPage;
  } else {
    return lengthOfCompanies - rowStartIdx;
  }
};

/* ============================== CUSTOM ACCORDION TABLE ============================== */
const CustomAccordionTable = ({
  data = [],
  columns = [],
  isPaginate = false,
  totalRecords = 0,
  onPaginationValueChange,
  className = '',
  setClientAdded,
  setColumnDetails,
  setCheckAccordion,
  fromDashboard,
  setActiveRow,
  activeRow
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const token = window.localStorage.getItem('token');
  const userRole = user?.role_id;
  const professionalUserId = user?.id;
  const redirectDomain = user?.firm?.firm_brand_details?.domain;

  const [individualClient, setIndividualClient] = useState([]);
  const [parentTableId, setParentTableId] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownOptions[0]);
  const [rowStartIdx, setRowStartIdx] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [checkExport, setCheckExport] = useState(false);
  const [item, setItem] = useState([]);
  const { status, message } = useSelector((state) => state.client);

  const reDirectToClientPage = (clientId) => {
    socket.emit('addAccess', {
      user_id: professionalUserId,
      access_user_id: clientId
    });
    if (process.env.REACT_APP_MODE == 'LOCAL') {
      window.open(
        `http://${redirectDomain}:3333/redirect/master-admin/${token}/${professionalUserId}/${clientId}/${userRole}`,
        '_self'
      );
    } else {
      window.open(
        `https://${redirectDomain}/redirect/master-admin/${token}/${professionalUserId}/${clientId}/${userRole}`,
        '_self'
      );
    }
  };

  useEffect(() => {
    setColumnDetails &&
      setColumnDetails({
        rowsPerPage: rowsPerPage,
        rowStartIdx: rowStartIdx,
        setRowStartIdx: setRowStartIdx
      });
  }, [rowsPerPage, rowStartIdx]);

  useEffect(() => {
    if (showStatus) {
      if (status === config.STATUS.SUCCESS) {
        setClientAdded(true);
        setOpenDeleteModal(false);
        toast.success(message);
        handleAccordionToggle(activeRow);
      } else {
        status === config.STATUS.ERROR;
        toast.error(message);
      }
    }
    return () => {
      setShowStatus(false);
    };
  }, [showStatus]);
  useEffect(() => {
    socket.on('sync_company_list', () => {
      handleAccordionToggle(activeRow);
    });
    return () => {
      socket.off('sync_company_list', () => {
        handleAccordionToggle(activeRow);
      });
    };
  }, []);
  const handleAccordionToggle = (rowIndex) => {
    setActiveRow(activeRow === rowIndex ? -1 : rowIndex);
    setIndividualClient(data[rowIndex]);
    if (activeRow === rowIndex) {
      setCheckAccordion &&
        setCheckAccordion({
          isOpen: false,
          accordionData: data[rowIndex]
        });
    } else {
      setCheckAccordion &&
        setCheckAccordion({
          isOpen: true,
          accordionData: data[rowIndex],
          setActiveRow: setActiveRow
        });
    }
  };

  const onHandleChangeRowsPerPageValue = (option) => {
    setRowsPerPage(option);
    setRowStartIdx(0);
    onPaginationValueChange({
      page: 1,
      limit: option.value
    });
  };

  const onHandleChangePage = (index) => {
    setActiveRow(-1);
    setRowStartIdx(index);
    onPaginationValueChange({
      page: Math.floor(index / rowsPerPage.value) + 1,
      limit: rowsPerPage.value
    });
  };

  const COLUMNS = [
    {
      label: 'First name',
      renderCell: (item) => item.first_name
    },
    {
      label: 'Last name',
      renderCell: (item) => item.last_name
    },
    { label: 'Email', renderCell: (item) => item.email },
    {
      label: 'Phone No.',
      renderCell: (item) => (item && item.phone_no ? formatPhoneNumber(item.phone_no) : '-')
    },
    {
      label: 'Date Added',
      renderCell: (item) =>
        item?.is_verified && item?.is_profile_set ? (
          moment(item.company_clients_created_date).format('MM/DD/YYYY')
        ) : (
          <span className="cs-regular-body-text-s cs-badge cs-warning">Pending</span>
        )
    },
    {
      label: 'Actions',
      renderCell: (item) => (
        <div className="cs-table-icons">
          <span
            onClick={() => {
              setOpenDeleteModal(true);
              setItem([item.user_id.toString()]);
            }}
            className="cursor-pointer delete-outlined cs-delete-btn">
            <CapsyncIcon title="delete-outlined" size="14" />
          </span>
          {!item?.is_profile_set && (
            <span className="collaboration-action-drop">
              <Dropdown className="cs-form-dropdown">
                <Dropdown.Toggle>
                  <span className="cursor-pointer cs-neutral-80">
                    <CapsyncIcon title="option-horizontal-filled" size="18" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleResendInvitation(item)}>
                    Resend invitation
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          )}
        </div>
      )
    },
    {
      label: '',
      renderCell: (item) => (
        <div
          className={`${item?.is_verified && item?.is_profile_set ? 'cs-neutral-80 cursor-pointer' : 'cs-neutral-80 cs-disabled'} text-end`}
          onClick={() => {
            item?.is_verified && item?.is_profile_set && handleAction(item)
          }}>
          <span
            className={
              item.is_verified && item.pfs_access_status === '2'
                ? 'cs-neutral-80 cursor-pointer'
                : 'cs-neutral-80 cs-disabled'
            }
>
            <CapsyncIcon title="chevron-right-outlined" size="18" />
          </span>
        </div>
      )
    }
  ];
  const DASHBOARDCOLUMN = [
    {
      label: 'First name',
      renderCell: (item) => item.first_name
    },
    {
      label: 'Last name',
      renderCell: (item) => item.last_name
    },
    { label: 'Email', renderCell: (item) => item.email },
    { label: 'Phone No.', renderCell: (item) => (item.phone_no ? formatPhoneNumber(item.phone_no) : '-') },
    {
      label: 'Status',
      renderCell: (item) =>
        item.is_verified && item.pfs_access_status === '2' ? (
          <span className="cs-regular-body-text-s cs-badge cs-success">Completed</span>
        ) : (
          <span className="cs-regular-body-text-s cs-badge cs-warning">Pending</span>
        )
    },
    {
      label: '',
      renderCell: (item) => (
        <div
          className="text-end"
          onClick={() => {
            item?.is_verified &&
              item.pfs_access_status === '2' &&
              reDirectToClientPage(item?.user_id);
          }}>
          <span
            className={
              item.is_verified && item.pfs_access_status === '2'
                ? 'cs-neutral-80 cursor-pointer'
                : 'cs-neutral-80 cs-disabled'
            }>
            <CapsyncIcon title="chevron-right-outlined" size="18" />
          </span>
        </div>
      )
    }
  ];
  const handleRemoveIndividualAndCompany = () => {
    item.map(String);
    dispatch(deleteClient({ remove_clients: item, remove_entirely: true })).then(() => {
      setShowStatus(true);
    });
  };
  const handleRemoveIndividual = () => {
    item.map(String);
    dispatch(
      deleteClient({
        remove_clients: item,
        remove_entirely: false,
        company_id: individualClient.id.toString()
      })
    ).then(() => {
      setShowStatus(true);
    });
  };
  if (!data.length) {
    return (
      <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">No data found</span>
    );
  }
  const handleAction = async (item) => {
    if (item.is_verified) {
      await dispatch(addRecentlyViewedCompany({ company_id: individualClient?.id })).unwrap();
      reDirectToClientPage(item?.user_id)
    }
  };
  const handleExportData = async () => {
    if (!checkExport) {
      setCheckExport(true);
      const token = window.localStorage.getItem('token');
      axios({
        url: `${config.BASE_URL}company/export`,
        method: 'get',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => {
          if (response && response.status === 200) {
            setCheckExport(false);
            fileDownload(response.data, 'CompanyData.pdf');
            toast.success(TOAST_MESSAGE.CLIENT_DATA_EXPORT_SUCCESS);
          } else {
            toast.error(TOAST_MESSAGE.CLIENT_DATA_EXPORT_ERROR);
          }
        })
        .catch((err) => {
          toast.error('Export Data :' + err.message);
        });
    }
  };

  const handleResendInvitation = async (item) => {
    dispatch(resendEmailVerification({ email: item.email, firm_id: String(parentTableId?.firm_id), is_team_collaboration: true })).then(
      (res) => {
        if (res.payload.status) {
          toast.success(res.payload.message);
        } else {
          toast.error(res.payload.message);
        }
      }
    );
  };
  return (
    <React.Fragment>
      <div className="cs-table-widget cs-company-table-widget">
        <Table className={`cs-table ${className}`}>
          <thead className="cs-thead">
            <tr>
              {columns.map((column, i) => (
                <th key={i} width={column && column.width}>
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="cs-tbody">
            {data?.length !== 0 &&
              data.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <tr
                    onClick={() => {
                      handleAccordionToggle(rowIndex);
                    }}>
                    {columns.map((column, columnIndex) => (
                      <td key={columnIndex}>{column.renderCell(row)}</td>
                    ))}
                  </tr>
                  {activeRow === rowIndex && (
                    <tr>
                      <td colSpan={columns.length} onClick={() => setParentTableId(row)}>
                        <Accordion defaultActiveKey={rowIndex}>
                          <Accordion.Item eventKey={rowIndex}>
                            <CustomTable
                              columns={fromDashboard && fromDashboard ? DASHBOARDCOLUMN : COLUMNS}
                              data={
                                fromDashboard && fromDashboard && data?.length !== 0 && data
                                  ? individualClient.firm_company.company_clients
                                  : data?.length !== 0 && data
                                    ? individualClient.company_clients
                                    : []
                              }
                            />
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      </div>
      {isPaginate && (
        <div className="cs-export-row">
          <span
            className={
              checkExport && checkExport
                ? 'cs-regular-body-text-m export-btn cs-neutral-80 cs-disabled'
                : 'cs-regular-body-text-m export-btn cs-primary cursor-pointer'
            }
            onClick={handleExportData}>
            <CapsyncIcon title="download-filled" size="18" /> Export Data
          </span>
          <div className="cs-custom-pagination">
            <div className="cs-common-add-dropdown">
              <span className="cs-light-body-text-m cs-neutral-80">Rows per page:</span>
              <Dropdown>
                <Dropdown.Toggle className="cs-light-body-text-m cs-neutral-80">
                  {rowsPerPage.label}
                  <CapsyncIcon title="chevron-down-outlined" size="12" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {paginationDropdownOptions.map((option) => {
                    const isSelected = option.label === rowsPerPage.label;
                    return (
                      <Dropdown.Item
                        key={option.value}
                        className={isSelected ? 'cs-pagination-select' : ''}
                        onClick={() => onHandleChangeRowsPerPageValue(option)}
                        value={option.value}>
                        {option.label}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="cs-light-body-text-m cs-neutral-80">
              <p>
                {rowStartIdx + 1}-
                {rowStartIdx + currentNumberOfRows(rowStartIdx, rowsPerPage.value, totalRecords)} of{' '}
                {totalRecords}
              </p>
            </div>
            <div className="cs-pagination-arrow">
              <Button
                onClick={() => onHandleChangePage(0)}
                className="cs-neutral-50"
                disabled={rowStartIdx === 0}>
                <CapsyncIcon title="chevron-left-skip-outlined" size="14" />
              </Button>
              <Button
                onClick={() => onHandleChangePage(rowStartIdx - rowsPerPage.value)}
                disabled={rowStartIdx === 0}
                className="cs-neutral-50">
                <CapsyncIcon title="chevron-left-outlined" size="14" />
              </Button>
              <Button
                onClick={() => onHandleChangePage(rowStartIdx + rowsPerPage.value)}
                disabled={!(rowStartIdx + rowsPerPage.value < totalRecords)}
                className="cs-neutral-50">
                <CapsyncIcon title="chevron-right-outlined" size="14" />
              </Button>
              <Button
                onClick={() =>
                  onHandleChangePage(
                    Math.floor((totalRecords - 1) / rowsPerPage.value) * rowsPerPage.value
                  )
                }
                disabled={!(rowStartIdx + rowsPerPage.value < totalRecords)}
                className="cs-neutral-50">
                <CapsyncIcon title="chevron-right-skip-outlined" size="14" />
              </Button>
            </div>
          </div>
        </div>
      )}
      {openDeleteModal && (
        <DeleteIndividualModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          handleRemoveIndividualAndCompany={handleRemoveIndividualAndCompany}
          handleRemoveIndividual={handleRemoveIndividual}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPES
CustomAccordionTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  isPaginate: PropTypes.bool,
  totalRecords: PropTypes.number,
  onPaginationValueChange: PropTypes.func,
  setClientAdded: PropTypes.func,
  setColumnDetails: PropTypes.func,
  className: PropTypes.string,
  setCheckAccordion: PropTypes.func,
  fromDashboard: PropTypes.bool,
  setActiveRow: PropTypes.func,
  activeRow: PropTypes.number
};

export default CustomAccordionTable;
