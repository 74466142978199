import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// COMPONENT IMPORTS
import SideBarItem from './SideBarItem';
import SideBarDropdown from './SideBarDropdown';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { SIDE_BAR_ITEMS } from '../../../constants/system';
import AddClientModal from '../../dashboard/components/AddClientModal';
import CapsyncLogo from '../../../assets/images/brand/capsync-logo.svg';
import CapFavicon from '../../../assets/images/brand/cap-icon-logo.svg';
import CreateCompanyModal from '../../dashboard/components/CreateCompanyModal';
import PaymentPendingAlertModal from '../../../components/PaymentPendingAlertModal';
import AlreadyExistingClientsModal from '../../dashboard/components/AlreadyExistingClientsModal';

// API
import { toggleSidebar } from '../../../slices/systemSlice';
import { useBrandDetails } from '../../../slices/brandDetailSlice';

/* ============================== SIDEBAR ============================== */
const SideBar = () => {
  const dispatch = useDispatch();

  const { isSidebarOpen } = useSelector((state) => state.system);

  const brandDetails = useBrandDetails();
  const [openIndividualModal, setOpenIndividualModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [validationCompany, setValidationCompany] = useState(false);
  const [isAlertModelOpen, setIsAlertModelOpen] = useState(false);

  useEffect(() => {
    if (991 >= width) dispatch(toggleSidebar(false));
    else dispatch(toggleSidebar(true));
  }, [width]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => setWidth(window.innerWidth), []);
  const handleNavItemClick = () => {
    if (isSidebarOpen && window.innerWidth <= 991) {
      dispatch(toggleSidebar());
    }
  };

  return (
    <div className={`cs-sidebar-main-widget ${isSidebarOpen ? 'sidebarOpen' : 'sidebarClose'}`}>
      <div className="cs-mobile-toggle">
        <span
          className="close-outlined cs-menu-toggle cs-icon"
          id="cs-mobile-toggle"
          onClick={() => dispatch(toggleSidebar())}>
          <CapsyncIcon title="close-outlined" size="18" />
        </span>
      </div>
      <div className="cs-dashboard-logo text-center">
        <Link id="capsync-logo" to={'/dashboard'} onClick={() => handleNavItemClick()}>
          <Image
            width="132"
            src={
              brandDetails && brandDetails?.is_domain_verified === true
                ? brandDetails.primary_logo_s3_url
                : CapsyncLogo
            }
            alt="brand-logo"
            className="capsync-logo cs-full-logo"
            classNameMain="cs-full-logo"
          />
          <Image
            width="40"
            src={
              brandDetails && brandDetails?.is_domain_verified === true
                ? brandDetails.small_logo_s3_url
                : CapFavicon
            }
            className="capsync-logo cs-icon-logo"
            alt="brand-logo-small"
            classNameMain="cs-icon-logo"
          />
        </Link>
      </div>

      <ul className="cs-sidebar-nav nav">
        {SIDE_BAR_ITEMS.map((item) =>
          item.subItems ? (
            <SideBarDropdown key={item.key} item={item} />
          ) : (
            <SideBarItem key={item.key} item={item} />
          )
        )}
      </ul>
      <div className="for-mob">
        <Button
          onClick={() => setOpenIndividualModal(true)}
          className="cs-neutral-10 mobile-add-icon">
          <CapsyncIcon title="add-filled" size="22" />
        </Button>
      </div>
      <div className="cs-sidebar-bottom">
        <div className="for-des">
          <Button
            type="submit"
            className="cs-btn-icon-primary lg-btn cs-regular-h5"
            onClick={() => {
              brandDetails?.is_payment_success
                ? setOpenIndividualModal(true)
                : setIsAlertModelOpen(true);
            }}>
            <span className="add-filled cs-neutral-10 icon cs-icon">
              <CapsyncIcon title="add-filled" size="18" />
            </span>
            <span className="cs-nav-text">Add Client</span>
          </Button>
        </div>
      </div>
      {openIndividualModal && (
        <AddClientModal
          handleCloseModel={() => setOpenIndividualModal(false)}
          setOpenIndividualModal={setOpenIndividualModal}
          setOpenCompanyModal={setOpenCompanyModal}
          formSidebar={true}
        />
      )}
      {openCompanyModal && (
        <CreateCompanyModal
          handleCloseModel={() => setOpenCompanyModal(false)}
          setOpenIndividualModal={setOpenIndividualModal}
          setOpenCompanyModal={setOpenCompanyModal}
          handleOpenModel={() => setValidationCompany(true)}
          formSidebar={true}
        />
      )}
      {validationCompany && (
        <AlreadyExistingClientsModal
          show={validationCompany}
          handleCloseModel={setValidationCompany}
        />
      )}
      {isAlertModelOpen && (
        <PaymentPendingAlertModal
          isOpen={isAlertModelOpen}
          onCloseClick={() => setIsAlertModelOpen(false)}
        />
      )}
    </div>
  );
};

export default SideBar;
