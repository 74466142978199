import React from 'react';
import PropTypes from 'prop-types';
import { useBrandDetails } from '../slices/brandDetailSlice';
import CommonSpinner from './CommonSpinner';
import { Image } from 'react-bootstrap';
const CapsyncLoader = ({ size, classParent = '', loaderSize = 35 }) => {
  const brandDetails = useBrandDetails();

  return (
    <div className="cs-loader">
      {brandDetails?.is_use_load_image ? (
        <Image
          className="spin-animation"
          src={brandDetails?.small_logo_s3_url}
          alt="Loading"
          width={size ? size : 192}
          height={size ? size : 192}
          draggable={false}
        />
      ) : (
        <CommonSpinner size={loaderSize} classParent={`cs-primary ${classParent}`} />
      )}
    </div>
  );
};

// PROPS TYPE
CapsyncLoader.propTypes = {
  size: PropTypes.number,
  loaderSize: PropTypes.number,
  classParent: PropTypes.any
};

export default CapsyncLoader;
