import React, { useEffect, useState } from 'react';
import { Accordion, Table, Badge, Button, Dropdown, Form, Row, Col } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { PDFDocument } from 'pdf-lib';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import moment from 'moment';
import axios from 'axios';

// COMPONENT IMPORTS
import OffCanavas from './OffCanavas';
import Modal from '../../components/Modal';
import { formatPhoneNumber } from '../../utils/common';
import CustomTable from '../../components/CustomTable';
import CapsyncIcon from '../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../constants/message';
import InputBox from '../../components/FormFields/InputBox';
import UserProgressIndicator from './UserProgressIndicator';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import { AddIndividualClientValidationSchema } from '../Settings/validation';

// API
import { shareSignature, signatureReminder } from '../../slices/requestSlice';

// CSS IMPORTS
import './Request.css';
import CapsyncLoader from '../../components/CapsyncLoader';

const paginationDropdownOptions = [
  {
    label: '10',
    value: 10
  },
  {
    label: '15',
    value: 15
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '25',
    value: 25
  },
  {
    label: '30',
    value: 30
  },
  {
    label: 'All',
    value: 'all'
  }
];

const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
  if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
    return rowsPerPage;
  } else {
    return lengthOfCompanies - rowStartIdx;
  }
};

/* ============================== REQUEST TABLE ============================== */
const RequestTable = ({
  isPaginate = false,
  totalRecords = 0,
  signatureList = {},
  onPaginationValueChange,
  setColumnDetails,
  setOnSubmit,
  tab,
  rowData,
  setRowData
}) => {
  const dispatch = useDispatch();

  const [activeRow, setActiveRow] = useState(-1);
  const [canvasDetails, setCanvasDetails] = useState({});
  const [accordionItemDetails, setAccordionItemDetails] = useState({});
  // const [rowData, setRowData] = useState({});
  const [show, setShow] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownOptions[0]);
  const [rowStartIdx, setRowStartIdx] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shareData, setShareData] = useState({
    item: '',
    values: ''
  });
  const [url, setUrl] = useState('');
  const [downloadItem, setDownloadItem] = useState('');

  useEffect(() => {
    setColumnDetails &&
      setColumnDetails({
        rowsPerPage: rowsPerPage,
        rowStartIdx: rowStartIdx,
        setRowStartIdx: setRowStartIdx
      });
  }, [rowsPerPage, rowStartIdx]);

  const handleDetailButton = (item) => {
    setCanvasDetails(item);
    setShow(true);
  };

  const onHandleChangeRowsPerPageValue = (option) => {
    setRowsPerPage(option);
    setRowStartIdx(0);
    onPaginationValueChange({
      page: 1,
      limit: option.value
    });
  };

  const onHandleChangePage = (index) => {
    setActiveRow(-1);
    setRowStartIdx(index);
    onPaginationValueChange({
      page: Math.floor(index / rowsPerPage.value) + 1,
      limit: rowsPerPage.value
    });
  };
  const columns = [
    {
      label: <span className="request-heading-icons">Requests</span>,
      renderCell: (item) => 'Signature Request',
      width: 248
    },
    {
      label: <span className="request-heading-icons">To</span>,
      renderCell: (item) =>
        item.signature_users.length > 1
          ? '-'
          : item.signature_users.map((name) => name.first_name + ' ' + name.last_name).join(', '),
      width: 248
    },
    {
      label: <span className="request-heading-icons">Company</span>,
      renderCell: (item) => (item.company_name ? item.company_name : '-'),
      width: 248
    },
    {
      label: 'Status',
      renderCell: (item) => {
        const acceptedUser = item?.signature_users?.filter((i) => i.status === 2).length;
        const rejectedUser = item?.signature_users?.filter((i) => i.status === 3).length;
        return (
          <UserProgressIndicator
            totalUser={item?.signature_users?.length}
            noOfUserAccepted={acceptedUser}
            noOfUserDeclined={rejectedUser}
          />
        );
      },
      width: 320
    },
    {
      label: <span className="request-heading-icons">Date</span>,
      renderCell: (item) => moment(item.createdAt).format('MM/DD/YYYY'),
      width: 260
    },
    {
      label: 'Action',
      renderCell: (item) => (
        <div
          className={`cs-table-icons ${item?.signature_users.length > 1 && tab === 'pending' ? 'acc-icon-end' : ''}`}>
          {item?.signature_users.length == 1 && tab === 'pending' && (
            <div>
              {isLoading && item.id === canvasDetails?.id ? (
                <span
                  className={
                    item?.signature_status !== 1 ? 'cs-disabled' : 'cs-neutral-80 cs-common-spinner'
                  }>
                  <CapsyncIcon title="loading-outlined" size="14" />
                </span>
              ) : (
                <span
                  className={item?.signature_status !== 1 ? 'cs-disabled' : 'cs-neutral-80'}
                  onClick={() => handleReminder(item)}>
                  <CapsyncToolTip
                    Child={'reminder-outlined'}
                    placement={'top'}
                    message={'Reminder'}
                    type="icon"
                    size="18"
                  />
                </span>
              )}
            </div>
          )}
          {tab === 'completed' && (
            <div className={tab === 'completed' ? 'acc-icon-start' : ''}>
              <span
                className={item?.signature_users[0].status !== 2 ? 'cs-disabled' : 'cs-neutral-80'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleShare(item);
                }}>
                <CapsyncToolTip
                  Child={'share-outlined'}
                  placement={'top'}
                  message={'Share'}
                  type="icon"
                  size="18"
                />
              </span>
              {isLoading && item.id === downloadItem?.id ? (
                <span
                  className={
                    item?.signature_status !== 1 ? 'cs-disabled cs-common-spinner' : 'cs-neutral-80'
                  }>
                  <CapsyncIcon title="loading-outlined" size="14" />
                </span>
              ) : (
                <span
                  className={
                    item?.signature_users[0].status !== 2 ? 'cs-disabled' : 'cs-neutral-80'
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleExportData(item);
                  }}>
                  <CapsyncToolTip
                    Child={'download-outlined'}
                    placement={'top'}
                    message={'Download'}
                    type="icon"
                    size="18"
                  />
                </span>
              )}
            </div>
          )}
          {item?.signature_users.length > 1 && activeRow === item.id ? (
            <span className="cs-neutral-80 req-accordion-icon">
              <CapsyncIcon title="chevron-up-outlined" size="18" />
            </span>
          ) : item?.signature_users.length === 1 ? (
            <div onClick={() => handleDetailButton(item)} className="req-details">
              <span className="cs-regular-body-text-l cs-neutral-90">Details</span>
              <span className="cs-neutral-80">
                <CapsyncIcon title="chevron-right-outlined" size="18" />
              </span>
            </div>
          ) : (
            <span className="cs-neutral-80 req-accordion-icon">
              <CapsyncIcon title="chevron-down-outlined" size="18" />
            </span>
          )}
        </div>
      ),
      width: 260
    }
  ];

  const statusBadge = (item) => {
    const statusMap = {
      1: 'Pending',
      2: 'Completed',
      3: 'Declined'
    };
    return statusMap[item.status];
  };

  const accordionTableData = [
    {
      label: 'First Name',
      renderCell: (item) => item.first_name,
      width: 220
    },
    {
      label: 'Last Name',
      renderCell: (item) => item.last_name,
      width: 220
    },
    {
      label: 'Email',
      renderCell: (item) => item.email,
      width: 220
    },
    {
      label: 'Phone Number',
      renderCell: (item) => (item && item.phone ? formatPhoneNumber(item.phone) : '-'),
      width: 220
    },
    {
      label: 'Status',
      renderCell: (item) => {
        const { status } = item;
        return (
          <Badge
            className={`cs-badge cs-regular-body-text-s ${status === 1 ? 'cs-warning' : status === 2 ? 'cs-success' : 'cs-danger'}`}>
            {statusBadge(item)}
          </Badge>
        );
      },
      width: 220
    },
    {
      label: 'Date',
      renderCell: (item) => (item.status === 2 ? moment(item.createdAt).format('MM/DD/YYYY') : '-'),
      width: 220
    },
    ...(tab === 'pending'
      ? [
          {
            label: 'Action',
            renderCell: (item) => (
              <div className="req-accordion-table-icons">
                <div className="cs-table-icons">
                  {tab === 'pending' && (
                    <div>
                      {isLoading && item.id === canvasDetails?.id ? (
                        <span
                          className={
                            item.status !== 1 ? 'cs-disabled' : 'cs-neutral-80 cs-common-spinner'
                          }>
                          <CapsyncIcon title="loading-outlined" size="14" />
                        </span>
                      ) : (
                        <span
                          className={
                            (item.status === 1 &&
                              !item.is_first_user &&
                              rowData.signature_status === 1) ||
                            (item.status === 2 && item.is_first_user)
                              ? 'cs-disabled'
                              : 'cs-neutral-80'
                          }
                          onClick={(e) => handleReminder(item)}>
                          <CapsyncToolTip
                            Child={'reminder-outlined'}
                            placement={'top'}
                            message={'Reminder'}
                            type="icon"
                            size="18"
                          />
                        </span>
                      )}
                    </div>
                  )}
                  <span
                    className={item.status !== 2 ? 'cs-disabled' : 'cs-neutral-80'}
                    onClick={() => {
                      handleShare(item);
                    }}>
                    <CapsyncToolTip
                      Child={'share-outlined'}
                      placement={'top'}
                      message={'Share'}
                      type="icon"
                      size="18"
                    />
                  </span>

                  {isLoading && item.id === downloadItem?.id ? (
                    <span
                      className={
                        item?.signature_status !== 1
                          ? 'cs-disabled cs-common-spinner'
                          : 'cs-neutral-80'
                      }>
                      <CapsyncIcon title="loading-outlined" size="14" />
                    </span>
                  ) : (
                    <span
                      className={item.status !== 2 ? 'cs-disabled' : 'cs-neutral-80'}
                      onClick={(e) => {
                        handleExportData(item);
                      }}>
                      <CapsyncToolTip
                        Child={'download-outlined'}
                        placement={'top'}
                        message={'Download'}
                        type="icon"
                        size="18"
                      />
                    </span>
                  )}
                </div>
              </div>
            ),
            width: 224
          }
        ]
      : []),
    {
      label: '',
      renderCell: (item) => (
        <div className="req-accordion-table-icons">
          <div className="req-details" onClick={() => handleDetailButton(item)}>
            <span className="cs-regular-body-text-l cs-neutral-90">Details</span>
            <span className="cs-neutral-80">
              <CapsyncIcon title="chevron-right-outlined" size="18" />
            </span>
          </div>
        </div>
      ),
      width: 224
    }
  ];
  const handleReminder = (item) => {
    if (
      item?.signature_status === 1 ||
      (item?.status === 1 && item?.is_first_user && rowData?.signature_status === 1) ||
      (item?.status === 1 && !item?.is_first_user && rowData?.signature_status === 2)
    ) {
      setCanvasDetails(item);
      setIsLoading(true);
      const signatureID = item.signature_users
        ? item.signature_users[0].signature_id.toString()
        : item.signature_id.toString();
      const userID = item.signature_users
        ? item.signature_users[0].id.toString()
        : item.id.toString();
      const status = item.signature_users ? item.signature_users[0].status : item.status;
      if (status === 1) {
        dispatch(
          signatureReminder({
            signature_id: signatureID,
            signature_user_id: userID
          })
        ).then((res) => {
          if (res.payload.status) {
            setIsLoading(false);
            setCanvasDetails({});
            toast.success(res.payload.message);
          } else {
            setIsLoading(false);
            setCanvasDetails({});
            toast.error(res.payload.message);
          }
        });
      }
    }
  };
  const handleExportData = async (item) => {
    const randomNumber = Math.floor(Math.random() * 9000) + 1000;
    // const values = rowData?.sign_pfs_link;
    const values =
      rowData && Object.keys(rowData).length > 0 && tab === 'pending'
        ? rowData?.sign_pfs_link
        : item.sign_pfs_link;
    if (values && (item?.status === 2 || item?.signature_status === 3)) {
      setIsLoading(true);
      setDownloadItem(item);
      try {
        const username = process.env.REACT_APP_HELLO_SIGN_USER_NAME;
        const password = process.env.REACT_APP_HELLO_SIGN_PASSWORD;

        const token = btoa(`${username}:${password}`);
        const headers = { Authorization: `Basic ${token}` };
        const response = await axios.get(values, {
          headers,
          responseType: 'arraybuffer'
        });
        if (response.status === 200) {
          const fileData = response.data;

          const pdfDoc = await PDFDocument.create();
          const downloadedPDF = await PDFDocument.load(fileData);
          const pageCount = downloadedPDF.getPageCount();

          for (let i = 0; i < pageCount; i++) {
            const [copiedPage] = await pdfDoc.copyPages(downloadedPDF, [i]);
            pdfDoc.addPage(copiedPage);
          }
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: 'application/pdf' });
          fileDownload(blob, `PFS${randomNumber}.pdf`);
          toast.success(TOAST_MESSAGE.SIGN_DOWNLOAD_SUCCESS);
          setIsLoading(false);
          setDownloadItem('');
        } else {
          console.error('Failed to fetch PDF data. Server responded with status:', response.status);
          setIsLoading(false);
          setDownloadItem('');
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
        setIsLoading(false);
        setDownloadItem('');
      }
    }
  };

  const handleShare = (item) => {
    if (
      (item?.signature_users && item?.signature_users[0]?.status === 2) ||
      (item?.status && item.status === 2)
    ) {
      setShowShareModal(true);
      setShareData({
        item: item
      });
    }
  };
  const { values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      notes: ''
    },

    validationSchema: AddIndividualClientValidationSchema,
    onSubmit: (values) => {
      if (shareData?.item?.sign_pfs_link) {
        fetchUrl(shareData?.item?.sign_pfs_link);
      } else if (rowData?.sign_pfs_link) {
        fetchUrl(rowData?.sign_pfs_link);
      }
      setShowPreviewModal(true);
      setShowShareModal(false);
      setShareData({
        item: shareData.item,
        values: values
      });
    }
  });
  const handleShareSubmit = () => {
    setLoading(true);
    dispatch(
      shareSignature({
        first_name: shareData?.values.first_name,
        last_name: shareData?.values.last_name,
        email: shareData?.values.email,
        signature_id: shareData?.item.signature_users
          ? shareData.item.signature_users[0].signature_id.toString()
          : shareData.item.signature_id.toString()
      })
    ).then((res) => {
      if (res.payload.status) {
        setShowPreviewModal(false);
        toast.success(res.payload.message);
        setShareData({
          item: '',
          values: ''
        });
        resetForm();
        setUrl('');
        setLoading(false);
      } else {
        toast.error(res.payload.message);
        setUrl('');
        setLoading(false);
      }
    });
  };
  const fetchUrl = async (values) => {
    if (values) {
      try {
        const username = process.env.REACT_APP_HELLO_SIGN_USER_NAME;
        const password = process.env.REACT_APP_HELLO_SIGN_PASSWORD;

        const token = btoa(`${username}:${password}`);
        const headers = { Authorization: `Basic ${token}` };
        const response = await axios.get(values, {
          headers,
          responseType: 'arraybuffer'
        });
        if (response.status === 200) {
          const fileData = response.data;

          const pdfDoc = await PDFDocument.create();
          const downloadedPDF = await PDFDocument.load(fileData);
          const pageCount = downloadedPDF.getPageCount();

          for (let i = 0; i < pageCount; i++) {
            const [copiedPage] = await pdfDoc.copyPages(downloadedPDF, [i]);
            pdfDoc.addPage(copiedPage);
          }

          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          setUrl(url);
        } else {
          console.error('Failed to fetch PDF data. Server responded with status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col>
          {Object.entries(signatureList).length !== 0 ? (
            <div className="cs-req-table">
              <Table className="cs-table">
                <thead className="cs-thead">
                  <tr>
                    {columns.map((column, i) => (
                      <th key={i} width={column && column.width}>
                        {column.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="cs-tbody">
                  {Object.entries(signatureList).map(([rowIndex, row]) => (
                    <React.Fragment key={rowIndex}>
                      <tr
                        id={`signature_request_${row?.id}`}
                        className={
                          (activeRow === row?.id && row?.signature_users.length > 1
                            ? 'open-accordion'
                            : '') ||
                          (row?.signature_users.length > 1 ? 'closed-accordion' : '') ||
                          ''
                        }
                        onClick={() =>
                          setActiveRow((prevRowIdx) => (prevRowIdx === row.id ? -1 : row.id))
                        }>
                        {columns.map((column, columnIndex) => (
                          <td key={columnIndex} onClick={() => setRowData(row)}>
                            {column.renderCell(row)}
                          </td>
                        ))}
                      </tr>
                      {activeRow === row?.id && row?.signature_users.length > 1 && (
                        <tr id={`signature_request_${row?.id}`}>
                          <td colSpan={accordionTableData.length} className="req-acc-data">
                            <div className="cs-req-acc-table">
                              <Accordion defaultActiveKey={row.id}>
                                <Accordion.Item
                                  eventKey={row.id}
                                  onClick={() => setAccordionItemDetails(row)}>
                                  <CustomTable
                                    columns={accordionTableData}
                                    data={row?.signature_users}
                                  />
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
              No data found
            </span>
          )}
        </Col>
      </Row>
      <OffCanavas
        setShow={setShow}
        show={show}
        canvasDetails={canvasDetails}
        accordionItemDetails={accordionItemDetails}
        setOnSubmit={setOnSubmit}
      />
      {isPaginate && (
        <div className="cs-export-row">
          <div className="cs-custom-pagination">
            <div className="cs-common-add-dropdown">
              <span className="cs-light-body-text-m cs-neutral-80">Rows per page:</span>
              <Dropdown>
                <Dropdown.Toggle className="cs-light-body-text-m cs-neutral-80">
                  {rowsPerPage.label} {/* Render the label property */}
                  <CapsyncIcon title="chevron-down-outlined" size="12" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {paginationDropdownOptions.map((option) => {
                    const isSelected = option.label === rowsPerPage.label;
                    return (
                      <Dropdown.Item
                        key={option.value}
                        className={isSelected ? 'cs-pagination-select' : ''}
                        onClick={() => onHandleChangeRowsPerPageValue(option)}
                        value={option.value}>
                        {option.label}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="cs-light-body-text-m cs-neutral-80">
              <p>
                {rowStartIdx + 1}-
                {rowStartIdx + currentNumberOfRows(rowStartIdx, rowsPerPage.value, totalRecords)} of{' '}
                {totalRecords}
              </p>
            </div>
            <div className="cs-pagination-arrow">
              <Button
                onClick={() => onHandleChangePage(0)}
                className="cs-neutral-50"
                disabled={rowStartIdx === 0}>
                <CapsyncIcon title="chevron-left-skip-outlined" size="14" />
              </Button>
              <Button
                onClick={() => onHandleChangePage(rowStartIdx - rowsPerPage.value)}
                disabled={rowStartIdx === 0}
                className="cs-neutral-50">
                <CapsyncIcon title="chevron-left-outlined" size="14" />
              </Button>
              <Button
                onClick={() => onHandleChangePage(rowStartIdx + rowsPerPage.value)}
                disabled={!(rowStartIdx + rowsPerPage.value < totalRecords)}
                className="cs-neutral-50">
                <CapsyncIcon title="chevron-right-outlined" size="14" />
              </Button>
              <Button
                onClick={() =>
                  onHandleChangePage(
                    Math.floor((totalRecords - 1) / rowsPerPage.value) * rowsPerPage.value
                  )
                }
                disabled={!(rowStartIdx + rowsPerPage.value < totalRecords)}
                className="cs-neutral-50">
                <CapsyncIcon title="chevron-right-skip-outlined" size="14" />
              </Button>
            </div>
          </div>
        </div>
      )}
      {showShareModal && (
        <Modal
          show={showShareModal}
          title="Share PFS"
          className="cs-md-modal"
          parentClassName="cs-common-modal-overlay"
          isCloseButton={false}
          body={
            <div className="decline-signature-request">
              <Row>
                <Col lg={6}>
                  <InputBox
                    label="First name"
                    name="first_name"
                    values={values}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg={6}>
                  <InputBox
                    label="Last name"
                    name="last_name"
                    values={values}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <InputBox
                    label="Email"
                    name="email"
                    type="email"
                    values={values}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="last-form-field">
                    <Form.Group className="cs-form-group">
                      <Form.Label className="cs-regular-sub-heading-xs cs-neutral-100">
                        Notes
                      </Form.Label>
                      <textarea
                        label="Notes"
                        name="notes"
                        autoComplete="off"
                        type="text"
                        className={
                          values.notes ? 'cs-input-field-active cs-textarea' : 'cs-textarea'
                        }
                        values={values}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </div>
          }
          cancelButtonLabel="Cancel"
          saveButtonLabel="Review & Send"
          handleOnSave={handleSubmit}
          handleOnCancel={() => {
            setShowShareModal(false);
            resetForm();
            setRowData({});
            setActiveRow(-1);
            setShareData({
              item: '',
              values: ''
            });
          }}
        />
      )}
      {showPreviewModal && (
        <Modal
          show={showPreviewModal}
          title={'PFS Preview'}
          // className={'cs-xl-modal'}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          parentClassName="pfs-pdf-modal cs-common-modal-overlay"
          className=""
          isCloseButton={false}
          body={
            <div className="pfs-pdf-box wm-req-pdf-container">
              {url ? (
                <iframe src={url} width="100%" height="100%" title="PDF Viewer"></iframe>
              ) : (
                // <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
                //   Personal Financial Statement Not found
                // </span>
                <CapsyncLoader />
              )}
            </div>
          }
          cancelButtonLabel="Cancel"
          saveButtonLabel="Send"
          handleOnSave={handleShareSubmit}
          handleOnCancel={() => {
            setShowPreviewModal(false);
            resetForm();
            setRowData({});
            setActiveRow(-1);
            setShareData({
              item: '',
              values: ''
            });
            setUrl('');
          }}
          disabled={url === '' || loading}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
RequestTable.propTypes = {
  signatureList: PropTypes.object,
  isPaginate: PropTypes.bool,
  totalRecords: PropTypes.number,
  onPaginationValueChange: PropTypes.func,
  setColumnDetails: PropTypes.func,
  setOnSubmit: PropTypes.func,
  tab: PropTypes.string,
  rowData: PropTypes.object,
  setRowData: PropTypes.func
};

export default RequestTable;
